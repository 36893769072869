<script setup>
import {useState} from "nuxt/app";
import StudentProfilePicture from "../../General/StudentProfilePicture";

const { user, loggedIn } = useState('auth').value;

</script>

<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="closeSidebar">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex flex-shrink-0 items-center px-4">
                <img class="h-8 w-auto" src="/images/logos/Icon.webp" alt="Sherpa" />
              </div>
              <div class="mt-5 h-0 flex-1 overflow-y-auto">
                <nav class="space-y-1 px-2">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900', 'group flex items-center px-6 py-4 text-base font-medium rounded-md']">
                    <component :is="item.icon" :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
                    {{ item.name }}
                  </a>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex justify-between flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">

        <div>
          <div class="flex flex-shrink-0 items-center px-4">
            <img class="h-10 w-auto" src="/images/logos/Icon.webp" alt="Sherpa" />
          </div>
          <div class="mt-5 flex flex-grow flex-col">

            <nav class="flex-1 pb-4 border-t border-gray-100">

              <p class="px-6 py-4 text-primary font-bold text-sm">Your Lessons</p>

              <div class="">
                <NuxtLink :to="item.href" v-for="item in navigation" :key="item.name" :class="[item.current ? 'border-primary text-primary font-medium bg-primary bg-opacity-5' : 'text-gray-500 hover:bg-primary hover:bg-opacity-5 border-white hover:border-primary hover:text-primary', 'border-l-4 group flex items-center px-6 py-3 duration-75 text-[0.92rem] text-gray-500']">
                  <component :is="item.icon" :class="[item.current ? 'text-primary' : 'group-hover:text-primary', 'mr-3 flex-shrink-0 h-6 w-6 duration-75']" aria-hidden="true" />
                  {{ item.name }}
                </NuxtLink>
              </div>

            </nav>
          </div>
        </div>



        <div>
          <a :href="supportLink" target="_blank" class="block border-t border-gray-200">
            <div class="flex py-4 items-center space-x-2 px-4 text-gray-600">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288" />
              </svg>

              <p>Contact Support</p>
            </div>
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {Cog6ToothIcon, UserCircleIcon, UsersIcon } from "@heroicons/vue/24/outline";
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {useRuntimeConfig} from "nuxt/app";

const config = useRuntimeConfig()

export default {
  name: "StudentSidebar",
  props: ['sidebarOpen'],
  components: {Dialog, DialogPanel, TransitionChild, TransitionRoot},
  data()
  {
  },

  methods: {
    closeSidebar()
    {
      this.$emit('close-sidebar');
    }
  },

  computed: {
    navigation(){
      return [
        { name: 'Lessons', href: '/student/lessons', icon: UsersIcon, current: this.$route.name.includes('lessons') },
      ]
    },

    supportLink()
    {
      return config.public.coreUrl + '/schools/support';
    }
  }
}
</script>
